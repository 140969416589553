import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 新增代码: 引入全部组件及样式
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import '@/styles/index.scss' // global css

import '@/permission' // permission control

// 字体图标
import './assets/iocn/iconfont.css'
// 导入SvgIcon组件
import SvgIcon from '@/components/SvgIcon'
const req = require.context('@/icons/svg', false, /\.svg$/)
req.keys().map(req)

const app = createApp(App)

app.component('svg-icon', SvgIcon)
app.use(store)
app.use(router)
app.use(ElementPlus)

app.mount('#app')
