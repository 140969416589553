
const getOrderDefaultState = () => {
    return {
        // 订单详情
        order_item_info: {}
    }
}

const state = getOrderDefaultState()

const mutations = {
    // reset order_item_info
    RESET_ORDER_INFO: (state, info) => {
        state.order_item_info = info
    }
}

const actions = {
    // 设置客户订单信息
    temSetOrderInfo ({ commit }, info) {
        commit('RESET_ORDER_INFO', info)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
