import request from '@/utils/request'

// json数据格式转换formdata
export function dataToForm (data) {
    const formData = new FormData()
    for (const key in data) {
        if (data[key] !== null) {
            formData.append(key, data[key])
        }
    }
    return formData
}

// 用户登录
export function login (data) {
    return request({
      url: '/api/user/third',
      method: 'post',
      data: dataToForm(data)
    })
}

// 用户登出
export function logout () {
    return request({
        url: '/backstage/user/user/logout',
        method: 'post'
    })
}

// 获取个人信息
export function getInfo () {
    return request({
        url: '/api/user/getuser',
        method: 'post'
    })
}

// 客户订单列表
export function GetAgreementList (data) {
    return request({
        url: '/api/contract/agreement_query',
        method: 'post',
        data: dataToForm(data)
    })
}

// 确认收货
export function PostConfirm (data) {
    return request({
        url: '/api/ebusiness/confirm',
        method: 'post',
        data: dataToForm(data)
    })
}

// 合同签署
export function PostSignQuery (data) {
    return request({
        url: '/api/contract/sign_query_new',
        method: 'post',
        data: dataToForm(data)
    })
    // return request({
    //     url: '/api/contract/sign_query',
    //     method: 'post',
    //     data: dataToForm(data)
    // })
}

// 同意协议
export function postAgreeWith (data) {
    return request({
        url: '/api/user/agree_with',
        method: 'post',
        data: dataToForm(data)
    })
}

// 身份证验证
export function postAuthentication (data) {
    return request({
        url: '/api/contract/data_authorization',
        method: 'post',
        data: dataToForm(data)
    })
}

// 查询结果
export function GetResult (data) {
    return request({
        url: '/api/ebusiness/authorization_result',
        method: 'post',
        data: dataToForm(data)
    })
}

// 获取省市区
export function GetAddressArea (data) {
    return request({
        url: '/api/user_address/area',
        method: 'post',
        data: dataToForm(data)
    })
}

// 提交 => 预提交列表
export function GetSubmissionInquire (data) {
    return request({
        url: '/api/manager/submission_list',
        method: 'post',
        data: dataToForm(data)
    })
}

// 提交 => 员工办理
export function PostSubmitUserData (data) {
    return request({
        url: '/api/ebusiness/user_authentication',
        method: 'post',
        data: dataToForm(data)
    })
}

// 品牌列表
export function GetBrandList (data) {
    return request({
        url: '/api/terminal_goods/brandlist',
        method: 'post',
        data: dataToForm(data)
    })
}

// 终端选择
export function GetGoodsChoice (data) {
    return request({
        url: '/api/terminal_goods/goodschoice',
        method: 'post',
        data: dataToForm(data)
    })
}

// 终端提交
export function PostNewInfoMation (data) {
    return request({
        url: '/api/ebusiness/new_information',
        method: 'post',
        data: dataToForm(data)
    })
}

// 规格列表
export function GetSpeceList (data) {
    return request({
        url: '/api/terminal_goods/goodsspece',
        method: 'post',
        data: dataToForm(data)
    })
}

// 补差款支付
export function PostWeChatPay (data) {
    return request({
        url: '/api/customer/wechat',
        method: 'post',
        data: dataToForm(data)
    })
}

// 规则
export function GetRules (data) {
    return request({
        url: '/api/shop/index/rules',
        method: 'post',
        data: dataToForm(data)
    })
}
