import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/login.vue'),
        meta: { title: '登录' }
    },
    {
        path: '/',
        name: 'MainPage',
        component: () => import('@/views/Main/index.vue'),
        redirect: '/cus_user',
        children: [
            {
                path: '/cus_user',
                name: 'CusUser',
                component: () => import('@/views/Main/CusUser.vue'),
                meta: { title: '个人中心', msg: '定制' }
            }
        ]
    },
    {
        path: '/cus_prove',
        name: 'CusProve',
        component: () => import('@/views/Prove'),
        meta: { title: '基本信息', msg: '定制' }
    },
    {
        path: '/cus_material',
        name: 'CusMaterial',
        component: () => import('@/views/Prove/Material.vue'),
        meta: { title: '资料上传', msg: '定制' }
    },
    {
        path: '/cus_pact',
        name: 'CusPact',
        component: () => import('@/views/Pact/CusPact.vue'),
        meta: { title: '进度', msg: '定制' }
    },
    {
        path: '/cus_anew',
        name: 'CusAnew',
        component: () => import('@/views/AnewConfiguration'),
        meta: { title: '终端选择', msg: '定制' }
    },
    {
        path: '/cus_machine_info',
        name: 'CusMachineInfo',
        component: () => import('@/views/AnewConfiguration/MachineInfo'),
        meta: { title: '终端确认', msg: '定制' }
    },
    {
        path: '/tips',
        name: 'TIPS',
        component: () => import('@/views/tips.vue'),
        meta: { title: '审核结果' }
    },
    {
        path: '/404',
        name: 'PageNotExist',
        component: () => import('@/views/404.vue')
    },
    { path: '/:catchAll(.*)', redirect: '/404' }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior: () => ({ y: 0 }),
    routes
})

// 重置路由, 但现在没实现
export function resetRouter () {
    const newRouter = router
    router.matcher = newRouter.matcher // reset router
}

export default router
