import Cookies from 'js-cookie'

const TokenKey = 'vue_admin_template_token'

// get token
export function getToken () {
    return Cookies.get(TokenKey)
}

// set token
export function setToken (token) {
    return Cookies.set(TokenKey, token)
}

// remove token
export function removeToken () {
    return Cookies.remove(TokenKey)
}
