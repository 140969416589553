import { login, logout, getInfo } from '@/api/index'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { resetRouter } from '@/router'

const getDefaultState = () => {
    return {
        token: getToken(),
        // 昵称
        name: '',
        // 头像
        avatar: '',
        // 详细信息
        info: ''
    }
}

const state = getDefaultState()

const mutations = {
    // reset state
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
    // set name
    SET_NAME: (state, name) => {
        state.name = name
    },
    // set avatar
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
    },
    // set info
    SET_INFO: (state, info) => {
        state.info = info
    },
    // set token
    SET_TOKEN: (state, token) => {
        state.token = token
    }
}

const actions = {
    // user login
    login ({ commit }, userInfo) {
        return new Promise((resolve, reject) => {
            login(userInfo)
                .then(res => {
                    if (res.code === 1000) {
                        const { userinfo } = res.data
                        // 存储 token 数据
                        localStorage.setItem('token', userinfo.token)
                        commit('SET_TOKEN', userinfo.token)
                        setToken(userinfo.token)
                        resolve()
                    } else {
                        reject(new Error('【登录失败】'))
                    }
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    // user logout
    logout ({ commit, state }) {
        return new Promise((resolve, reject) => {
            logout(state.token)
                .then(() => {
                    removeToken() // must remove  token  first
                    resetRouter()
                    commit('RESET_STATE')
                    resolve()
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    // remove token
    resetToken ({ commit }) {
        return new Promise(resolve => {
            localStorage.removeItem('token')
            removeToken()
            commit('RESET_STATE')
            resolve()
        })
    },

    // get userInfo
    getInfo ({ commit, state }) {
        return new Promise((resolve, reject) => {
            getInfo()
                .then(({ data }) => {
                    const { nickname, avatar, is_sign, province, third_id } = data.userinfo
                    if (!data) {
                        reject('Verification failed, please Login again.')
                    }
                    commit('SET_NAME', nickname)
                    commit('SET_AVATAR', avatar)
                    commit('SET_INFO', data.userinfo)
                    localStorage.setItem('is_sign', is_sign)
                    localStorage.setItem('province', province)
                    if (third_id) {
                        localStorage.setItem('thirdId', third_id)
                    }
                    resolve(data.userinfo)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
