<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  created () {
    /**
     * iphone 手机不支持 beforeunload 方法，支持 pagehide
     * 判断是否是 ios 系统
     * 在页面加载时读取 sessionStorage 里的状态信息
     *      替换 store 根状态，Object.assign 对象合并，并且是浅层拷贝
     */
    const isOnIOS = navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i)
    const eventName = isOnIOS ? 'pagehide' : 'beforeunload'
    if (sessionStorage.getItem('store')) {
        this.$store.replaceState(
            Object.assign(
                {},
                this.$store.state,
                JSON.parse(sessionStorage.getItem('store'))
            )
        )
    }
    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener(eventName, () => {
      sessionStorage.setItem('store', JSON.stringify({ 'order': this.$store.state.order }))
    })
  }
}
</script>

<style lang="scss"></style>
